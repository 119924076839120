import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Layout } from "../../components/Layout"
import CompanyHead from "../../components/CompanyInfoHeader"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"
import CompanyInfoFooterLinks from "../../components/CompanyInfoFooterLinks"
import { useLocationData } from "../../hooks/locationHook"

const StyledIntro = styled.div`
  width: 90%;
  margin: 50px auto;
  border-bottom: 1px solid #dadada;
  h2 {
    font-weight: bold;
    font-size: 1.32em;
    margin-bottom: 1.62em;
  }
  .introDiv {
    p {
      line-height: 1.32em;
      padding: 0.32em 0;
    }
  }
  .imgSec {
    width: 80%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    margin: 30px auto;
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
  ${() =>
    media.sp(css`
      margin: 30px auto;
      .imgSec {
        width: 100%;
        .gatsby-image-wrapper {
          width: 100%;
        }
      }
    `)}
`

const StyledLists = styled.div`
  width: 80%;
  margin: 0 auto;
  .infoList {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 1.62em;
    h5 {
      width: 20%;
      font-weight: bold;
    }
    .infoCon {
      width: 78%;
    }
  }
  ${() =>
    media.sp(css`
      width: 80%;
      .infoList h5 {
        width: 100%;
        margin-bottom: 0.62em;
        font-size: 0.9em;
        color: gray;
        text-decoration: underline;
      }
      .infoList .infoCon {
        width: 100%;
      }
    `)}
`

const StyledAdds = styled.div`
  width: 80%;
  margin: 50px auto;
  font-size: 0.9em;
  div {
    h4 {
      font-weight: bold;
      line-height: 1.62em;
    }
    .tableKit {
      padding: 30px 0 50px;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-end;
      justify-content: center;
      h5 {
        width: 50%;
        border-bottom: 1px solid gray;
        padding: 1.62em 0.62em 0.32em;
      }
      p {
        width: 20%;
        padding-right: 1.62em;
        border-bottom: 1px solid #dadada;
        text-align: right;
      }
    }
  }
`

const MenDep = ({ location }) => {
  const data = useStaticQuery(query)
  useLocationData(location.pathname)
  return (
    <Layout>
      {data.zoo.nodes.map((panda, index) => (
        <Fragment key={index}>
          <CompanyHead
            heroImage={panda.heroMedia.childImageSharp.fluid}
            logoImage={panda.logoMedia && panda.logoMedia.childImageSharp.fluid}
            logoText={panda.logoAlternativeText}
          />
          <StyledIntro>
            <h2 dangerouslySetInnerHTML={{ __html: panda.titleText }} />
            <div
              className="introDiv"
              dangerouslySetInnerHTML={{ __html: panda.contentsUnderTitle }}
            />
            <div className="imgSec">
              {panda.mediaLists.map(pig => (
                <Image
                  fluid={pig.mediaList.childImageSharp.fluid}
                  key={pig.id}
                />
              ))}
            </div>
          </StyledIntro>

          <StyledLists>
            {panda.companyInfo.map((cow, index) => (
              <div className="infoList" key={index}>
                <h5>{cow.titleName}</h5>
                <div
                  className="infoCon"
                  dangerouslySetInnerHTML={{ __html: cow.companyInfos }}
                />
              </div>
            ))}
          </StyledLists>

          <StyledAdds>
            <div dangerouslySetInnerHTML={{ __html: panda.addMisc }} />
          </StyledAdds>
        </Fragment>
      ))}
      <CompanyInfoFooterLinks
        outLink="http://www.menshoku.net/"
        innerLink="/company-profiles/#menshoku"
        backLink="/#anchorBiz"
      />
    </Layout>
  )
}

export const query = graphql`
  {
    zoo: allStrapiCompanyInfoBlog(filter: { strapiId: { eq: 5 } }) {
      nodes {
        logoMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logoAlternativeText
        titleText
        contentsUnderTitle
        companyInfo {
          id
          titleName
          companyInfos
        }
        addMisc
        heroMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mediaLists {
          id
          mediaAlt
          mediaList {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default MenDep
